import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { SessionContext } from '../SessionContext';
import config from '../config';
import 'animate.css';
import { URL_SERVICIOS } from '../services/UrlWebServis';

const LoginPage = () => {

  const [Email, setEmail] = useState("")
  const [Password, setPassword] = useState("")
  
  const { setUser, setToken } = useContext(SessionContext);
  
  const loginuser = async (e) => {
    e.preventDefault();

    const datos = {
        email: Email,
        password: Password
    };

    try {
        const response = await fetch(`${URL_SERVICIOS}api/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(datos)
        });

        const result = await response.json();

        if (!response.ok) {
            throw new Error(result.mensaje || 'Error en el login');
        }

        if (result.error === false) {
            //login(result.usuario, result.usuario.token); // Guardar los datos del usuario en el estado
            setUser(result.usuario)
            setToken(result.token)

       /*      Swal.fire({
                title: 'Login exitoso',
                text: `Bienvenido ${result.usuario.nombre}`,
                icon: 'success',
                confirmButtonText: 'Aceptar'
            }); */

            // Redirigir al dashboard u otra página
            // navigate("/h");
        } else {
            Swal.fire({
                title: 'Usuario no válido',
                text: result.mensaje,
                icon: 'error',
                confirmButtonText: 'Aceptar'
            });
        }
    } catch (error) {
        Swal.fire({
            title: 'Error de red',
            text: error.message,
            icon: 'error',
            confirmButtonText: 'Aceptar'
        });
    }
};


  return (
  <main className='fondologin'>
        <div className="container">
        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">

              {/* <div className="d-flex justify-content-center py-4">
                <a href="index.html" className="logo d-flex align-items-center w-auto">
                  <img src="assets/img/LogoCnotas.png" alt="" />
                  <span className="d-none d-lg-block">{config.NOMBRE_APP} {config.VERSION_APP}</span>
                </a>
              </div> */}

              <div className="card mb-3 animate__animated animate__pulse">

              <div className="card-body p-0 auth-header-box">
                                    <div className="text-center p-3">
                                        <a href="index.html" className="logo logo-admin">
                                            <img src="assets/img/logo-sm.png" height="50" alt="logo" className="auth-logo" />
                                        </a>
                                        <h4 className="mt-3 mb-1 fw-semibold text-white font-18">{config.NOMBRE_APP} {config.VERSION_APP}</h4>   
                                        <p className="text-muted  mb-0">Inicie sesión para continuar</p>  
                                    </div>
                                </div>
                <div className="card-body login">

{/*                   <div className="pt-2 pb-2">
                     <h5 className="card-title text-center pb-0 fs-4">Ingresa a tu cuenta</h5> 
                    <span className="text-center small">Inicie sesión para continuar</span>
                  </div>
 */}
                  <form className="row g-3 needs-validation"  onSubmit={loginuser}>

                   
                    <div className="col-12" style={{marginTop: '25px'}}>
                      <label htmlFor="yourUsername" className="form-label label">Correo Electrónico</label>
                      <div className="input-group has-validation">
                        {/* <span className="input-group-text" id="inputGroupPrepend">@</span> */}
                        <input type="text" name="Email" 
                            className="form-control form-control-sm" style={{  color: '#004085', fontWeight: 'bold' }} id="yourUsername" required  
                            onChange={e => setEmail(e.target.value)} />
                        <div className="invalid-feedback">Please enter your username.</div>
                      </div>
                    </div>

                    <div className="col-12" style={{marginTop: '2px'}}>
                      <label htmlFor="yourPassword" className="form-label label">Contraseña</label>
                      <input type="password" name="Password" className="form-control form-control-sm" style={{  color: '#004085', fontWeight: 'bold' }} id="yourPassword" required  onChange={e => setPassword(e.target.value)} /> 
                      <div className="invalid-feedback">Please enter your password!</div>
                    </div>

                    <div className="form-group row mt-3">
                                            <div className="col-sm-6">
                                                <div className="form-check form-switch form-switch-success">
                                                    <input className="form-check-input" type="checkbox" id="customSwitchSuccess" />
                                                    <label className="form-check-label" htmlFor="customSwitchSuccess" style={{fontSize: '.8125rem'}}>Recordarme</label>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 text-end">
                                                <a href="auth-recover-pw.html" className="text-muted font-13"><i className="dripicons-lock"></i> ¿Olvidaste tu contraseña?</a>                                    
                                            </div>
                                        </div>
                   
                    <div className="col-12">
                      <button className="btn btn-primary w-100" type="submit" disabled={!Email || !Password }>Ingresar <i className="bi bi-box-arrow-in-right"></i></button>
                    </div>
                   {/*  <div className="col-12">
                      <p className="small mb-0">Don't have account? <a href="pages-register.html">Create an account</a></p>
                    </div> */}
                  </form>

                </div>
              </div>

              <div className="credits">
                    Designed by <a href={config.URL_EMPRESA}>{config.EMPRESA}</a>
              </div>

            </div>
          </div>
        </div>

      </section>

    </div>
  </main>
  )
}

export default LoginPage