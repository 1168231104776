import React, { createContext, useState, useEffect } from 'react';
import config from './config';

export const SessionContext = createContext();

export const SessionProvider = ({ children }) => {
  const storedUser = JSON.parse(localStorage.getItem('userNational')) || null;
  const storedToken = storedUser?.token || null;

  const [user, setUser] = useState(storedUser);
  const [token, setToken] = useState(storedToken);
  const defaultAvatar = 'usuario.png';
  const [FotoPerfil, setFotoPerfil] = useState(user?.avatar || defaultAvatar);

  const login = (userNational, jwtToken) => {
    setUser(userNational);
    saveToken(jwtToken);
  };

  const logout = () => {
    console.log("logut")
    setUser(null);
    clearToken();
  };

  const saveToken = (jwtToken) => {
    setToken(jwtToken);
    if (user) {
      localStorage.setItem('userNational', JSON.stringify({ ...user, token: jwtToken }));
    }
  };

  const clearToken = () => {
    setToken(null);
    localStorage.removeItem('userNational');
  };

  useEffect(() => {
    if (user) {
      localStorage.setItem('userNational', JSON.stringify(user));
    } else {
      localStorage.removeItem('userNational');
    }
  }, [user]);

  useEffect(() => {
    const validateToken = async () => {
      if (!token) return;

      try {
        const response = await fetch(`${config.API_URL_PPAL}/api/validate-token`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          console.warn('Token inválido o expirado. Cerrando sesión...');
          logout();
        }
      } catch (error) {
        console.error('Error al validar el token:', error);
        logout();
      }
    };

    validateToken();
  }, [token]);

  useEffect(() => {
    if (user && FotoPerfil) {
      const updatedUser = { ...user, avatar: FotoPerfil };
      setUser(updatedUser);
    }
  }, [FotoPerfil]);

  return (
    <SessionContext.Provider value={{ user, token, setUser, setToken, login, logout, FotoPerfil, setFotoPerfil }}>
      {children}
    </SessionContext.Provider>
  );
};
