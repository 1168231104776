import React, { useContext, useEffect, useState } from 'react'
import { GetData } from '../services/GetData'
import Swal from 'sweetalert2'
import BotonTabla from '../components/BotonTabla'
import { SessionContext } from '../SessionContext'
import { useHttpRequest } from '../services/useHttpRequest'
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

const Seminarios = () => {
    const [ListaSeminarios, setListaSeminarios] = useState([])
    const [Loading, setLoading] = useState(false)
    const [Refrest, setRefrest] = useState(false)


    const [IdSeminario, setIdSeminario] = useState(0);
    const [NombreSem, setNombreSem] = useState('');
    const [FechaSem, setFechaSem] = useState('');
    const { token } = useContext(SessionContext); 

    const HttpRequest = useHttpRequest();

    const listar_seminarios = async () => {
      const response = await HttpRequest('api/seminarios', 'GET');
      setListaSeminarios(response);
      setLoading(false)
    }

    useEffect(() => {
        listar_seminarios()
    }, [Refrest])

  const Modificar = (Cat) => {
        setIdSeminario(Cat.id_seminario)
        setNombreSem(Cat.nombre_seminario)
        setFechaSem(Cat.fecha_sem)

        const modal = document.getElementById('modalCategoria');

        // Verificar si el modal existe antes de intentar abrirlo
        if (modal) {
          // Usar el método modal() para abrir el modal
          const modalInstancia = new window.bootstrap.Modal(modal);
          modalInstancia.show();
        }
  }  

  const NuevaSeminario = () => {
        setIdSeminario(0)
        setNombreSem("")
        setFechaSem("")
        const modal = document.getElementById('modalCategoria');

        // Verificar si el modal existe antes de intentar abrirlo
        if (modal) {
        // Usar el método modal() para abrir el modal
        const modalInstancia = new window.bootstrap.Modal(modal);
        modalInstancia.show();
        }
    }

    const Eliminar = (id_seminario, nombre) => {
      Swal.fire({
          title: '¿Estás seguro?',
          text: `¡No podrás revertir esto! ¿Estás seguro de eliminar el seminario: ${nombre}?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sí, eliminarlo',
      }).then(async (result) => {
          if (result.isConfirmed) {
              try {
                  const response = await HttpRequest(`api/seminarios/${id_seminario}`, 'DELETE');
  
                  if (!response.error) {
                      Swal.fire({
                          title: 'Eliminado',
                          text: 'El seminario ha sido eliminado correctamente.',
                          icon: 'success',
                      });
                      setRefrest(!Refrest); // Actualiza la lista
                  } else {
                      Swal.fire({
                          title: 'Error al eliminar',
                          text: response.message,
                          icon: 'error',
                          confirmButtonText: 'Aceptar',
                      });
                  }
              } catch (error) {
                  Swal.fire({
                      title: 'Error al eliminar',
                      text: `Error al eliminar el seminario: ${error.message}`,
                      icon: 'error',
                      confirmButtonText: 'Aceptar',
                  });
                  console.error('Error al eliminar el seminario:', error);
              }
          }
      });
  };
  

    const validarFormulario = () => {
        if (
            NombreSem.trim() === '' && FechaSem === ''
       
        ) {
          alert('Por favor, complete todos los campos requeridos.');
          return false;
        }
        return true;
      };

      const limpiarFormulario = () => {
        setIdSeminario(0)
        setNombreSem("")
    }

    const GuardarSeminario = async () => {
      
      if (!validarFormulario()) {
          return;
      }
  
      const datos = {
          id_seminario: IdSeminario,
          nombre_seminario: NombreSem,
          fecha_sem: FechaSem
      };
  
    // Define el método y la URL según el valor de IdSeminario
    const metodo = IdSeminario > 0 ? 'PUT' : 'POST';
    const endpoint = IdSeminario > 0 ? `api/seminarios/${IdSeminario}` : 'api/seminarios';

    try {
        // Llama a HttpRequest con el método y los datos correspondientes
        const response = await HttpRequest(endpoint, metodo, datos);
  
          if (!response.error) {
              Swal.fire({
                  title: 'Guardar',
                  text: 'Seminario guardado correctamente.',
                  icon: 'success',
                  confirmButtonText: 'Aceptar',
              });
              limpiarFormulario();
              setRefrest(!Refrest);
          } else {             
              toast.error(response.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
          }
      } catch (error) {
        
          toast.error('Error al guardar el seminario: ' + error.message);
          console.error('Error al guardar el seminario:', error);
      }
  };
  





  return (
    <>
         <section className='section dashboard'>
            <div className="card">
                  
                <div className="card-body pb-0">
                    <h5 className="card-title">Listado de Seminarios</h5>

                    <div className='row'>                          
                            <div className='col-md-2'>
                                <button type="button" className="btn btn-primary btn-sm mt-4 ml-2" onClick={NuevaSeminario} >
                                    <i className="bi bi-person-plus"></i> Nuevo Seminario
                                </button>
                            </div>
                    </div>    

                    {Loading && 
                    <div className="progress mt-3">
                        <div className="progress-bar progress-bar-striped bg-info progress-bar-animated" role="progressbar" style={{width : '100%'}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>      
                    }      
                     <div className="table-responsive">
                        <table className="table table-striped  mt-2">
                            <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Id</th>
                                <th scope="col">Nombre Seminario</th>
                                <th scope="col">Fecha</th>
                                <th scope="col" align='center'>N° Pastic</th>
                                <th scope="col" align='center'>Op</th>
                            </tr>
                            </thead>
                            <tbody>
                            {ListaSeminarios.map((cate, index) => 
                            <tr index={cate.id_seminario}>
                                <th scope="row">{index+1}</th>
                                <td>{cate.id_seminario}</td>
                                <td>{cate.nombre_seminario}</td>
                                <td align='center'>{cate.fecha_sem}</td>
                                <td align='center'>{cate.total_participantes}</td>
                                <td>
                                  <BotonTabla icono='lapiz.png' titulo='Modificar' evento={()=>Modificar(cate)} />
                                  <BotonTabla icono='borrar.png' titulo='Eliminar' evento={()=>Eliminar(cate.id_seminario, cate.nombre_seminario)} />
                                </td>
                            </tr>
                            )}    
                            
                            </tbody>
                        </table>
                    </div>
                    </div>
                </div>
            </section>    


            <div className="modal fade" id="modalCategoria" tabIndex="-1" data-bs-backdrop="static">

            <div className="modal-dialog modal-md">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">
                      <i className="bi bi-pencil-square"></i> {IdSeminario === 0 ? <>Nuevo Seminario</> : <>Modificar Seminario N° {IdSeminario}</>}
                    </h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    <div className="form-group">
                      <label htmlFor="nombreProv">Nombre *</label>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        id="nombreProv"
                        value={NombreSem}
                        onChange={(e) => setNombreSem(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="nombreProv">Fecha *</label>
                      <input
                        type="date"
                        className="form-control form-control-sm"
                        id="nombreProv"
                        value={FechaSem}
                        onChange={(e) => setFechaSem(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="modal-footer">
                  <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal"><i className="bi bi-door-closed"></i> Cerrar</button>
                    <button type="button" className="btn btn-primary" disabled={!NombreSem} onClick={GuardarSeminario}>Guardar</button>
                  </div>
                </div>
              </div>            

            </div>
            <ToastContainer />
    </>
  )
}

export default Seminarios