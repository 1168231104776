// config.js
const config = {
    LOGO: '/assets/img/logo.jpg',
    VERSION_APP: 1.5,
    NOMBRE_APP: 'Seminarios National',
    API_URL_PPAL: 'https://national.epikominteractive.com/api/public/',
    API_URL_INFORMES: 'https://national.epikominteractive.com/informe/',
    API_URL_FOTOS: 'https://national.epikominteractive.com/uploads/fotosperfil/',
    API_URL_PATH: 'https://national.epikominteractive.com',
    EMPRESA: 'Epikom Solucion',
    URL_EMPRESA: '#'
  };
  
  export default config;
  