import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { URL_SERVICIOS } from '../services/UrlWebServis';
import { SessionContext } from '../SessionContext';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { useHttpRequest } from '../services/useHttpRequest';

const ImportarArchivo = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [seminarios, setSeminarios] = useState([]);
    const [selectedSeminario, setSelectedSeminario] = useState("");
    const [listaParticipantes, setListaParticipantes] = useState([]);
    const [listaParticipantesError, setListaParticipantesError] = useState([]);
    const [Loading, setLoading] = useState(false);
    const { token } = useContext(SessionContext); 

    const HttpRequest = useHttpRequest();

    const fetchSeminarios = async () => {
        try {
            const response = await HttpRequest('api/seminarios', 'GET');
            setSeminarios(response);
        } catch (error) {
            console.error('Error al obtener los seminarios:', error);
        }
    };

    useEffect(() => {
        fetchSeminarios();
    }, []);

    // Manejo del archivo seleccionado
    const onDrop = useCallback((acceptedFiles) => {
        // Solo permite un archivo, puedes ajustar si necesitas múltiples
        const file = acceptedFiles[0];
        setSelectedFile(file);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: '.csv', // Solo acepta archivos CSV
        maxFiles: 1,    // Solo permite un archivo
    });

    const importarArchivo = async () => {
        if (!selectedFile) {
           // alert('Por favor selecciona un archivo antes de importar.');
            toast.error('Por favor selecciona un archivo antes de importar.');
            return;
        }

        if (!selectedSeminario){
            toast.error('Debe seleccionar el Seminario');
            return;
        }
    
        setListaParticipantes([])
        const formData = new FormData();
        formData.append('file', selectedFile); // Asegúrate de usar 'file' como clave
        formData.append('id_seminario', selectedSeminario); // Asegúrate de usar 'file' como clave
    
        try {
            setLoading(true)
            const response = await fetch(URL_SERVICIOS + 'api/importar-participantes', {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`, // Solo el encabezado de autorización
                },
                body: formData, // Enviar el archivo como FormData
            });
    
            const result = await response.json();
           
    
            if (!result.error) {
                setListaParticipantes(result.success)
                setListaParticipantesError(result.failed)
                toast.success('Archivo importado correctamente');
            } else {
                toast.error(`Error: ${result.message}`);
            }
            setLoading(false)
        } catch (error) {
            console.error('Error al importar el archivo:', error);
            toast.error(`Error al importar el archivo: ${error}`);
            setLoading(false)
        }
    };
    
    
    

    return (
        <section className='section dashboard'>
            <div className="card">
                <div className="card-body pb-0">
                    <h5 className="card-title">Importar Archivo CSV</h5>

                    <div className='row mb-2'>
                        <div className='col-md-5'>
                        <strong>Seminario: </strong>
                        <select className="form-select form-select-sm estilocampo" onChange={(e) => setSelectedSeminario(e.target.value)} value={selectedSeminario || ''}>
                            <option value="" disabled>Selecciona un seminario</option>
                            {seminarios.map((seminario) => (
                                <option key={seminario.id_seminario} value={seminario.id_seminario}>
                                    {seminario.nombre_seminario}
                                </option>
                            ))}
                        </select>
                        </div>
                    </div>
                    <div
                        {...getRootProps({
                            className: 'dropzone',
                            style: {
                                border: '2px dashed #cccccc',
                                borderRadius: '5px',
                                padding: '20px',
                                textAlign: 'center',
                                cursor: 'pointer',
                                marginBottom: '20px',
                            },
                        })}
                    >
                        <input {...getInputProps()} />
                        {isDragActive ? (
                            <p>Suelta el archivo aquí...</p>
                        ) : (
                            <p>Arrastra un archivo CSV aquí o haz clic para seleccionarlo.</p>
                        )}
                    </div>
                    {selectedFile && (
                        <p style={{ marginTop: '10px', fontStyle: 'italic' }}>
                            Archivo seleccionado: {selectedFile.name}
                        </p>
                    )}
                    <button
                        onClick={importarArchivo}
                        className="btn btn-primary"
                        style={{ marginTop: '20px', marginBottom: '10px' }}
                    >
                        Importar Archivo
                    </button>
                </div>
            </div>

            <div className="card">
            <div className="card-body pt-2">
            {Loading && 
                    <div className="progress mt-3">
                        <div className="progress-bar progress-bar-striped bg-info progress-bar-animated" role="progressbar" style={{width : '100%'}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>      
                    }      
                    
                    {listaParticipantes.length > 0 && 
                    <>
                    <div className='alert alert-primary alert-dismissible fade show'>
                    Se importaron los sigiuentes participantes
                    </div>  
                     <div className="table-responsive">
                        <table className="table table-striped  mt-2">
                            <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Nombres</th>
                                <th scope="col">Email</th>
                                <th scope="col">Op</th>
                            </tr>
                            </thead>
                            <tbody>
                            {listaParticipantes.map((part, index) => 
                            <tr index={part.id_seminario}>
                                <th scope="row">{index+1}</th>
                                <td>{part.nombres}</td>
                                <td>{part.email}</td>
                                <td align='center'>{part.relation}</td>
                               
                            </tr>
                            )}    
                            
                            </tbody>
                        </table>
                    </div>
                    </>
                    }
                      
                    {listaParticipantesError.length > 0 && 
                    <>
                    <div className='alert alert-danger alert-dismissible fade show'>
                        No se pudieron importar los sigiuentes participantes
                    </div>  
                     <div className="table-responsive">
                        <table className="table table-striped  mt-2">
                            <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Nombres</th>
                                <th scope="col">Email</th>
                                <th scope="col">Op</th>
                            </tr>
                            </thead>
                            <tbody>
                            {listaParticipantesError.map((part, index) => 
                            <tr index={part.id_seminario}>
                                <th scope="row">{index+1}</th>
                                <td>{part.nombres}</td>
                                <td>{part.email}</td>
                                <td align='center'>{part.relation}</td>
                               
                            </tr>
                            )}    
                            
                            </tbody>
                        </table>
                    </div>
                    </>
                    }
                </div>
                </div>
             <ToastContainer />
        </section>
    );
};

export default ImportarArchivo;
