import React, { useEffect, useState } from 'react'
import { useHttpRequest } from '../services/useHttpRequest';

const HomePrincipal = () => {

    const [loading, setLoading] = useState(false);
    const [numParticipantes, setnumParticipantes] = useState(0);
    const [numSeminarios, setNumSeminarios] = useState(0);

       const HttpRequest = useHttpRequest();
    
       useEffect(() => {
        const fetchSeminarios = async () => {
            setLoading(true);
            try {
                const response = await HttpRequest('api/participantes/count', 'GET', null);

                if (!response.error) {
                    setnumParticipantes(response.total_participantes); // Asume que el campo "data" contiene la lista
                } else {
                    console.error('Error al obtener seminarios:', response.message);
                }
            } catch (error) {
                console.error('Error en la solicitud:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchSeminarios();
    }, []); // Se ejecuta solo una vez al montar el componente

       useEffect(() => {
        const fetchSeminarios = async () => {
            setLoading(true);
            try {
                const response = await HttpRequest('api/seminarios/count', 'GET', null);

                if (!response.error) {
                    setNumSeminarios(response.total_seminarios); // Asume que el campo "data" contiene la lista
                } else {
                    console.error('Error al obtener seminarios:', response.message);
                }
            } catch (error) {
                console.error('Error en la solicitud:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchSeminarios();
    }, []); // Se ejecuta solo una vez al montar el componente
    

  return (
    <>
        <div className="pagetitle">
            <h1>Dashboard</h1>
            <nav>
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="/home">Inicio</a></li>
                <li className="breadcrumb-item active">Dashboard</li>
            </ol>
            </nav>
        </div>
        
        <section className="section dashboard">
            <div className="row">
        
            
            <div className="col-lg-12">
                <div className="row">
        
            
                <div className='col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-12'>
                    <div className="card info-card sales-card">
        
        
                    <div className="card-body">
                        <h5 className="card-title">Participantes <span>| Registrados</span></h5>
        
                        <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                            <i className="bi bi-people"></i>
                        </div>
                        <div className="ps-3">
                            <h6>{numParticipantes}</h6>
                           {/*  <span className="text-success small pt-1 fw-bold">12%</span> <span className="text-muted small pt-2 ps-1">increase</span> */}
        
                        </div>
                        </div>
                    </div>
        
                    </div>
                </div>
        
                {/* <!-- Revenue Card --> */}
                <div className='col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-12'>
                    <div className="card info-card revenue-card">
        
        
                    <div className="card-body">
                        <h5 className="card-title">E-Mail <span>| Enviados</span></h5>
        
                        <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                            <i className="bi bi-person-dash"></i>
                        </div>
                        <div className="ps-3">
                            <h6></h6>
                           {/*  <span className="text-success small pt-1 fw-bold">8%</span> <span className="text-muted small pt-2 ps-1">increase</span> */}
        
                        </div>
                        </div>
                    </div>
        
                    </div>
                </div>
                {/* <!-- End Revenue Card --> */}
        
                {/*  <!-- Customers Card --> */}
                <div className='col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-12'>
        
                    <div className="card info-card customers-card">
        
                   
                    <div className="card-body">
                        <h5 className="card-title">Seminarios <span></span></h5>
        
                        <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                            <i className="bi bi-person-badge"></i>
                        </div>
                        <div className="ps-3">
                            <h6>{numSeminarios}</h6>
                           {/*  <span className="text-danger small pt-1 fw-bold">12%</span> <span className="text-muted small pt-2 ps-1">decrease</span> */}
        
                        </div>
                        </div>
        
                    </div>
                    
                    </div>
        
                </div>
                {/* <!-- End Customers Card --> */}

                {/*  <!-- Customers Card --> */}
                <div className="col-xxl-3 col-md-3">
        
                    <div className="card activo-card customers-card">
        
                    
        
                    <div className="card-body">
                        <h5 className="card-title">Período <span>| Actual</span></h5>
        
                        <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                            <i className="bi bi-stopwatch"></i>
                        </div>
                        <div className="ps-3">
                            <h6></h6>
                            <span className="text-danger small pt-1 fw-bold"></span> <span className="text-muted small pt-2 ps-1"></span>
        
                        </div>
                        </div>
                        
        
                    </div>
                    
                    </div>
        
                </div>
                {/* <!-- End Customers Card --> */}

                {/*  <!-- Customers Card --> */}
                
                {/* <!-- End Customers Card --> */}
                <div className="row">
                    
                </div>
           
                    {/* <!-- End sidebar recent posts--> */}
        
             
                {/* <!-- End News & Updates --> */}
        
            </div>
            {/* <!-- End Right side columns --> */}
        
            </div>
            </div>
        </section>
  </>
  )
}

export default HomePrincipal