import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { URL_SERVICIOS } from './UrlWebServis';
import { SessionContext } from '../SessionContext';

export const useHttpRequest = () => {
    const { logout, token } = useContext(SessionContext); // Usa el contexto para el logout

    const HttpRequest = async (type, method = 'GET', data = null) => {
        

        if (!token) {
            logout(); // Redirige al login si no hay token
            return Promise.reject('No token provided');
        }

        const url_api = `${URL_SERVICIOS}${type}`;
        const options = {
            method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        };

        if (method !== 'GET' && data) {
            options.body = JSON.stringify(data);
        }

        try {
            const response = await fetch(url_api, options);

            if (response.status === 401) {
                console.error('Token inválido o expirado. Redirigiendo al login...');
                logout(); // Limpia el contexto y la sesión
                throw new Error('No autorizado: Token inválido o expirado.');
            }

            if (!response.ok) {
                throw new Error(`Error en la solicitud: ${response.statusText}`);
            }

            return await response.json();
        } catch (error) {
            console.error('Error en la petición:', error);
            throw error;
        }
    };

    return HttpRequest;
};
