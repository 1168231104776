import { Link, NavLink } from "react-router-dom"
import { getToken, getTipoUser, Usuario } from '../services/Usuario';

const SideBar = () => {

    const usuario = Usuario();
    const tipousario = parseInt(usuario.id_tipos_usuario)

  return (
    <aside id="sidebar" className="sidebar">

        <ul className="sidebar-nav" id="sidebar-nav">

        <li className="nav-item">
            <NavLink className="nav-link collapsed" to="/home/principal" activeclassname="active">
            <i className="bi bi-grid"></i>
            <span>Dashboard</span>
            </NavLink>
        </li>
        
                <li>
                    <NavLink to={"/home/seminarios"} className="nav-link collapsed">
                        <i className="bi bi-journal-album"></i><span>Seminarios</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={"/home/importar"} className="nav-link collapsed">
                        <i className="bi bi-folder-symlink"></i><span>Importar Archivo</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={"/home/participantes"} className="nav-link collapsed">
                        <i className="bi bi-people"></i><span>Participantes</span>
                    </NavLink>
                </li>
                <li>
                <NavLink to={"/home/enviaremail"} className="nav-link collapsed">
                    <i className="bi bi-cursor"></i><span>Enviar Email</span>
                    </NavLink>
                </li>
       
      

        </ul>

    </aside>
  )
}

export default SideBar