export function GetData(type, token) {
    const url_api = localStorage.getItem("URL_SERVICIOS");

    if (!token) {
        return Promise.reject('No token provided');
    }

    return new Promise((resolve, reject) => {
        fetch(`${url_api}${type}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
        .then((response) => {
            if (!response.ok) {
                throw new Error(`Error en la solicitud: ${response.statusText}`);
            }
            return response.json();
        })
        .then((responseJson) => {
            resolve(responseJson);
        })
        .catch((error) => {
            console.error('Error en la petición:', error);
            reject(error);
        });
    });
}
